import { Link } from "react-router-dom";
import PublicHeader from "../layout/PublicHeader";
import PublicFooter from "../layout/PublicFooter";
import SearchVisas from "./SearchVisas";
import Visas from "./Visas";
export default function Home() {

    return (
        <main>
            <PublicHeader></PublicHeader>
            <section className="hero -type-10 -min">
                <div className="hero__bg" style={{backgroundColor:"#EB662B"}}>
                </div>
                <SearchVisas></SearchVisas>
            </section>
            <Visas></Visas>
            <PublicFooter></PublicFooter> 
        </main>
    );
}