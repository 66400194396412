import { Link } from "react-router-dom";
export default function PublicHeader() {

    return (
        <header className="header -type-10 js-header">
            <div className="header__container container">
                <div className="headerMobile__left">
                    <button className="header__menuBtn js-menu-button">
                        <i className="icon-main-menu" />
                    </button>
                </div>
                <div className="header__logo">
                <Link to={"/"} className="ml-30 logo-font">
                        TRAWTEL
                    </Link>
                </div>
                <div className="header__right xl:d-none">
                    <Link to={"/"} className="button -sm bg-white rounded-200 text-dark-1 ml-30">
                    Help
                    </Link>
                    <Link to={"/"} className="button -sm -outline-white rounded-200 text-white ml-30">
                        Log in
                    </Link>
                </div>
            </div>
        </header>
    );
}