import React, { useEffect, useState } from 'react';
import visasdata from '../common/data/singlevisas.json'
import { Link } from 'react-router-dom';
export default function Visas() {
    const [items, setItems] = useState([]);
    useEffect(() => {
        // Fetch JSON data from the public folder
        setItems(visasdata)
    }, []);
    return (
        <section className="layout-pb-xl layout-pt-xl">
            <div className="container">
                <div className="row y-gap-10 justify-between items-center y-gap-10">
                    {/* <div className="col-auto">
                        <h2 className="text-30 md:text-24 me-2">
                            Apply
                            <div className="dropdown -type-list js-dropdown js-form-dd">
                                <div className="dropdown__button text-light-7 js-button">
                                    <span className="js-title">Visa on Arrival</span>
                                    <i className="icon-chevron-down ml-5 text-18" />
                                </div>
                                <div className="dropdown__menu text-16 fw-500 border-1 js-menu-items">
                                    <div className="dropdown__item" data-value="london">Visa on Arrival</div>
                                    <div className="dropdown__item" data-value="new_york">Tourist Visa</div>
                                    <div className="dropdown__item" data-value="berlin">Business Visa</div>
                                </div>
                            </div>
                        </h2>
                    </div> */}
                    <div className="row y-gap-30 pt-20 sm:pt-20">
                        {items.length > 0 && items.map(i =>
                            <div className="col-lg-3 col-md-6">
                                <Link to={"/visa/" + i.visaId} className="tourCard -type-1 d-block border-1 bg-white hover-shadow-1 overflow-hidden rounded-12 bg-white -hover-shadow">
                                    <div className="tourCard__header">
                                        <div className="tourCard__image ratio ratio-28:20">
                                            <img src={"/img/visacountries/" + i.flag + ".png"} alt={i.flag} className="img-ratio" />                                            
                                        </div>
                                    </div>
                                    <div className="tourCard__content px-20 py-10">
                                        <div className="tourCard__location d-flex items-center text-13 text-light-2">
                                            <i className="icon-pin d-flex text-16 text-light-2 mr-5" />
                                            {i.countryName}
                                        </div>
                                        <h3 className="tourCard__title text-16 fw-500 mt-5">
                                            <span>{i.visaName}</span>
                                        </h3>
                                        <div className="tourCard__rating d-flex items-center text-13 mt-5">
                                            <div className="d-flex x-gap-5">
                                                <span className="text-dark-2">{i.visaType}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-between items-center border-1-top text-13 text-dark-1 pt-10 mt-10">
                                            <div className="d-flex items-center">
                                                <i className="icon-clock text-16 mr-5" />
                                                Get in {i.processingTime} days
                                            </div>
                                            <div>From <span className="text-16 fw-500">INR {i.adultPrice}</span></div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}