import { Link, useParams } from "react-router-dom";
import PublicHeader from "../layout/PublicHeader";
import PublicFooter from "../layout/PublicFooter";
import visaData from "../common/data/b2cvisas.json";
import { useEffect, useState } from "react";
import SearchVisas from "./SearchVisas";
export default function Visa() {
    const params = useParams();
    const [visa, setVisa] = useState({})
    const [visas, setVisas] = useState({})
    const [visaCountry, setVisaCountry] = useState({})
    const [visaDocuments, setVisaDocuments] = useState([])
    const [visaTerms, setVisaTerms] = useState([])
    const [visaFAQ, setVisaFAQ] = useState([])
    async function getVisa() {
        for (let country of visaData) {
            const data = country.visas?.find(visa => visa.visaId === String(params.visaId));
            if (data) {
                console.log("Result for visaId:", params.visaId, "=>", data);
                setVisa(data)
            }
        }
        return null; // Return null if no match is found
    };
    function getCountryByVisaId() {
        console.log("Params:", params); // Debugging
        const visaId = params.visaId;
        if (!visaId) {
            console.error("No visaId provided in params");
            return;
        }
        // Find the country that contains the visaId in its visas array
        const matchingCountry = visaData.find((country) =>
            country.visas?.some((visa) => visa.visaId === visaId)
        );

        if (matchingCountry) {
            setVisaCountry(matchingCountry);
            setVisas(matchingCountry.visas);
            console.log(matchingCountry);
            setVisa(matchingCountry.visas[0])
            setVisaDocuments(matchingCountry.visaDocuments)
            setVisaFAQ(matchingCountry.visaFAQs)
            setVisaTerms(matchingCountry.visaTerms)
        } else {
            console.warn("No country found for visaId:", visaId);
        }
    };
    useEffect(() => {
        getCountryByVisaId();
    }, [params.visaId]);
    return (
        <main>
            <PublicHeader></PublicHeader>
            <section className="hero -type-10 -min">
                <div className="hero__bg" style={{ backgroundColor: "#EB662B" }}>
                </div>
                <SearchVisas></SearchVisas>
            </section>
            {visa ? (
                <div>
                    <section className="py-30 mt-20">
                        <div className="container">
                            <div className="row y-gap-20 justify-between items-end">
                                <div className="col-auto">
                                    <h2 className="text-40 sm:text-30 lh-14">
                                        {visaCountry.name}
                                    </h2>
                                    <div className="row x-gap-20 y-gap-20 items-center pt-20">
                                        <div className="col-auto">
                                            <div className="d-flex items-center">
                                                <div className="d-flex x-gap-5 pr-10">
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                    <i className="flex-center icon-star text-yellow-2 text-12" />
                                                </div>
                                                4.8 (269)
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex items-center">
                                                <i className="icon-reservation text-16 mr-5" />
                                                30K+ applied
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="d-flex x-gap-30 y-gap-10">
                                        <a href="#" className="d-flex items-center">
                                            <i className="icon-share flex-center text-16 mr-10" />
                                            Share
                                        </a>
                                        <a href="#" className="d-flex items-center">
                                            <i className="icon-heart flex-center text-16 mr-10" />
                                            Wishlist
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="js-pin-container">
                        <div className="container">
                            <div className="row y-gap-30 justify-between">
                                <div className="col-lg-8">
                                    <div data-anim-child="delay-2" className="tourSingleGrid -type-1 mt-0">
                                        <div className="tourSingleGrid__grid mobile-css-slider-2">
                                            <img src={visaCountry.imageSrc} alt={visaCountry.url} />
                                        </div>
                                    </div>
                                    <div className="row y-gap-20 justify-between items-center layout-pb-md mt-10">
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-clock" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Visa Type</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.visaType}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-clock" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Length of the stay</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.stayPeriod}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-clock" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Validity</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.validity}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6">
                                            <div className="d-flex items-center">
                                                <div className="flex-center size-50 rounded-12 border-1">
                                                    <i className="text-20 icon-teamwork" />
                                                </div>
                                                <div className="ml-10">
                                                    <div className="lh-16">Entry</div>
                                                    <div className="text-14 text-light-2 lh-16">{visa.entry}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line mt-0 mb-10" />
                                    <h2 className="text-30">Process</h2>
                                    <div className="mt-10">
                                        <div className="roadmap -roadmap">
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon">
                                                </div>
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 1 : Apply on Trawtel</div>
                                                        <p></p>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Submit your documents and pay the amount
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon" />
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 2 : Documents will be verified</div>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Our team will verify the documents and submit to immigration
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon" />
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 3 : Processing Visa</div>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Our team will work with immigration to get your visa.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="roadmap__item">
                                                <div className="roadmap__icon" />
                                                <div className="roadmap__wrap">
                                                    <div className="d-flex items-center justify-between">
                                                        <div className="roadmap__title">Step 4 : Get Your Visa</div>
                                                    </div>
                                                    <div className="active">
                                                        <div className="roadmap__content">
                                                            Our team will work with immigration to get your visa.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line mt-30 mb-30" />
                                    <h2 className="text-30">Documents Required</h2>
                                    <div className="row x-gap-130 y-gap-20 pt-20">
                                        <div className="col-lg-12">
                                            <div className="y-gap-15">
                                                {visaDocuments.length > 0 && visaDocuments.map(vd =>
                                                    <div className="d-flex">
                                                        <i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" />
                                                        {vd.documentName}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line mt-30 mb-30" />
                                    <h2 className="text-30">FAQ</h2>
                                    <div className="accordion -simple row y-gap-20 mt-30 js-accordion">
                                        {visaFAQ.length > 0 && visaFAQ.map((vd, index) =>
                                            <div className="col-12">
                                                <div className="accordion__item px-20 py-15 border-1 rounded-12">
                                                    <div className="accordion__button d-flex items-center justify-between">
                                                        <div className="button text-16 text-dark-1">{vd.question}</div>
                                                        <div className="accordion__icon size-30 flex-center bg-light-2 rounded-full">
                                                            <i className="icon-plus text-13" />
                                                            <i className="icon-minus text-13" />
                                                        </div>
                                                    </div>
                                                    <div className="accordion__content">
                                                        <div className="pt-20">
                                                            <p>{vd.answer}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="line mt-30 mb-30" />
                                    <h2 className="text-30">Terms</h2>
                                    <div className="row x-gap-130 y-gap-20 pt-20">
                                        <div className="col-lg-12">
                                            <div className="y-gap-15">
                                                {visaTerms.length > 0 && visaTerms.map(vd =>
                                                    <div className="d-flex">
                                                        <i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" />
                                                        {vd.termsandConditions}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="line mt-60 mb-60" />
                                    <h2 className="text-30">Customer Reviews</h2>
                                    <div className="pt-30">
                                        <div className="row justify-between">
                                            <div className="col-auto">
                                                <div className="d-flex items-center">
                                                    <div className="size-40 rounded-full">
                                                        <img src="img/reviews/avatars/1.png" alt="image" className="img-cover" />
                                                    </div>
                                                    <div className="text-16 fw-500 ml-20">Ali Tufan</div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="text-14 text-light-2">April 2023</div>
                                            </div>
                                        </div>
                                        <div className="d-flex items-center mt-15">
                                            <div className="d-flex x-gap-5">
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                            </div>
                                            <div className="text-16 fw-500 ml-10">Take this tour! Its fantastic!</div>
                                        </div>
                                        <p className="mt-10">Great for 4-5 hours to explore. Really a lot to see and tons of photo spots. Even have a passport for you to collect all the stamps as a souvenir. Must see for a Harry Potter fan.</p>
                                    </div>
                                    <div className="pt-30">
                                        <div className="row justify-between">
                                            <div className="col-auto">
                                                <div className="d-flex items-center">
                                                    <div className="size-40 rounded-full">
                                                        <img src="img/reviews/avatars/1.png" alt="image" className="img-cover" />
                                                    </div>
                                                    <div className="text-16 fw-500 ml-20">Ali Tufan</div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="text-14 text-light-2">April 2023</div>
                                            </div>
                                        </div>
                                        <div className="d-flex items-center mt-15">
                                            <div className="d-flex x-gap-5">
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                                <i className="icon-star text-yellow-2 text-10" />
                                            </div>
                                            <div className="text-16 fw-500 ml-10">Take this tour! Its fantastic!</div>
                                        </div>
                                        <p className="mt-10">Great for 4-5 hours to explore. Really a lot to see and tons of photo spots. Even have a passport for you to collect all the stamps as a souvenir. Must see for a Harry Potter fan.</p>
                                    </div> 
                                    <button className="button -md -outline-accent-1 text-accent-1 mt-30">
                                        See more reviews
                                        <i className="icon-arrow-top-right text-16 ml-10" />
                                    </button>*/}
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-end js-pin-content">
                                        <div className="tourSingleSidebar">
                                            <div class="sidebar -type-1 rounded-12">
                                                <div class="sidebar__header bg-accent-1">
                                                    <div class="text-15 text-white fw-500">Price Summary</div>
                                                </div>
                                            </div>
                                            <div className="d-flex items-center justify-between mt-20">
                                                <div className="text-14">Travellers</div>
                                                <div className="d-flex items-center js-counter">
                                                    <button className="button size-30 border-1 rounded-full js-down">
                                                        <i className="icon-minus text-10" />
                                                    </button>
                                                    <div className="flex-center ml-10 mr-10">
                                                        <div className="text-14 size-20 js-count">3</div>
                                                    </div>
                                                    <button className="button size-30 border-1 rounded-full js-up">
                                                        <i className="icon-plus text-10" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="line mt-20 mb-20" />
                                            <div className="d-flex items-center justify-between">
                                                <div className="text-18 fw-500">Government Fee:</div>
                                                <div className="text-18 fw-500">INR 5000</div>
                                            </div>
                                            <div className="d-flex items-center justify-between">
                                                <div className="text-18 fw-500">Trawtel Fee:</div>
                                                <div className="text-18 fw-500">INR 1000</div>
                                            </div>
                                            <div className="d-flex items-center justify-between">
                                                <div className="text-18 fw-500">Total:</div>
                                                <div className="text-18 fw-500">INR 6000</div>
                                            </div>
                                            <button className="button -md -dark-1 col-12 bg-accent-1 text-white mt-20">
                                                Apply Now
                                                <i className="icon-arrow-top-right ml-10" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="layout-pt-xl layout-pb-xl">
                        <div className="container">
                            <div className="row">
                                <div className="col-auto">
                                    <h2 className="text-30">Check other visas from {visaCountry.name}</h2>
                                </div>
                            </div>
                            <div className="row y-gap-30 pt-40 sm:pt-20">
                        {visas.length > 0 && visas.map(i =>
                            <div className="col-lg-3 col-md-6">
                                <Link to={"/visa/" + i.visaId} className="tourCard -type-1 d-block border-1 bg-white hover-shadow-1 overflow-hidden rounded-12 bg-white -hover-shadow">
                                    <div className="tourCard__header">
                                        <div className="tourCard__image ratio ratio-28:20">
                                            <img src={"/img/visacountries/" + i.flag + ".png"} alt={i.flag} className="img-ratio" />                                            
                                        </div>
                                    </div>
                                    <div className="tourCard__content px-20 py-10">
                                        <div className="tourCard__location d-flex items-center text-13 text-light-2">
                                            <i className="icon-pin d-flex text-16 text-light-2 mr-5" />
                                            {visaCountry.name}
                                        </div>
                                        <h3 className="tourCard__title text-16 fw-500 mt-5">
                                            <span>{i.visaName}</span>
                                        </h3>
                                        <div className="tourCard__rating d-flex items-center text-13 mt-5">
                                            <div className="d-flex x-gap-5">
                                                <span className="text-dark-2">{i.visaType}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-between items-center border-1-top text-13 text-dark-1 pt-10 mt-10">
                                            <div className="d-flex items-center">
                                                <i className="icon-clock text-16 mr-5" />
                                                Get in {i.processingTime} days
                                            </div>
                                            <div>From <span className="text-16 fw-500">INR {i.adultPrice}</span></div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                        </div>
                    </section>
                </div>)
                : (
                    <p>Visa not found!</p>
                )}
            <PublicFooter></PublicFooter>
        </main>
    );
}